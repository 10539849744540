.container_card {
    border-radius: 3px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    height: fit-content;
    padding: 20px;
}

.card_img {
    display: flex;
    width: 160px;
    overflow: hidden;
    border-radius: 100%;
    margin: -100px auto 0;
}

.card_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card_info {
    text-align: center;
}