
  
  .table_generica {
    width: 100%;
    padding: 20px;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  
  .table_generica>thead>tr {
    color: #000;
    height: 60px;
    border-bottom: 1px solid #d9d9d9;

  
  }
  
  .table_generica>thead>tr>th {
    border-bottom: 1px solid #d9d9d9;
    padding: 5px;
    font-weight: 600;
    border-style: none;
    font-size: 14px;
  
  }
  
  .table_generica>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
  
  .table_generica>tbody>tr:nth-of-type(even) {
    background-color: var(--gris-facility);
  }
  
  
  .table_generica>tbody>tr>td {
    border-bottom: solid #dadada 1px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    color: #515151;
  
    padding:  10px;
    cursor: pointer !important;
  
  
  }
  
  .table_generica>tbody>tr:hover {
    background-color: #f7f7f7 !important;
    cursor: default !important;
  }
  
  
  .sort_table{
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  
  
  .ascending::after {
    content: "▼";
    display: inline-block;
    margin-left: 1em;
  }
  
  .descending::after {
    content: "▲";
    display: inline-block;
    margin-left: 1em;
  }
  
  
  
  
  
  .filtersTable {
    border-top: 2px solid #fff;
  }
  
  .filtersTable td {
    padding: 5px;
  }
  
  .accionesTabla {
  
    display: flex;
    justify-content: end;
    gap: 2px;
  }
  
  .accionesTabla>button {
    border: none;
    color: #333;
    padding: 4px;
  
  }
  