.container_header {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    padding: 20px;
}

.container_left {
    width: 600px;
    display: flex;
    gap: 10px;
}

.container_right {
    display: flex;
}