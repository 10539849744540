body {
  /*
  background: #536976;  
  background: -webkit-linear-gradient(to bottom, #292E49, #536976);  
  background: linear-gradient(to bottom, #292E49, #536976); 
  */
  /*Ocultando overflow     overflow: hidden;*/


  background: #eee !important;
  overflow: hidden;
  font-weight: 500;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  /*
  font-family:  "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active,
.show>.btn-default.dropdown-toggle {
  background-color: #fff !important;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}


.mdb-skin .btn-default:not([disabled]):not(.disabled):active,
.mdb-skin .btn-default:not([disabled]):not(.disabled).active,
.show>.mdb-skin .btn-default.dropdown-toggle {
  background-color: transparent !important;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}






code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.side-nav {
  top: '65px',
}

.table td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 500 !important;
}



.classic-tabs .nav li a.active {
  color: white;
  border-bottom: 3px solid;
}

.classic-tabs .nav li a {
  padding: 8px !important;
}



.menu {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

.menu li {
  display: inline-block;
  list-style: none;
}

.menu li:not(:nth-last-child(4)) {
  margin: 0 0px;
}

.menu li:nth-last-child(4) {
  margin-left: auto;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: rgb(188, 186, 187);
}

/*Tree cambio de estilos*/
.ant-tree-treenode-switcher-close {
  text-align: left;
}

.ant-tree-treenode-switcher-open {
  text-align: left;
}

.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: rgb(188, 186, 187);
}

.ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: rgb(236, 233, 235);
}

.tratarimagen {
  min-width: "35px";
  max-height: "65px"
}

.carousel-indicators {
  background-color: #485563;

}

.ant-tree li {
  margin: 0;
  padding: 0px 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}

.mdb-datatable table tr {
  border-bottom: 1px solid #dee2e6;
  text-align: left;

}


/*Image viewer references generico */


.react-simple-image-viewer__modal_Generico {

  display: flex;
  align-items: center;
  position: fixed;
  /*
  padding: 155px 60px 0px 60px;
  */
  right: 0;
  top: 0;

  height: 100%;
  width: 100%;
  background-color: none repeat scroll 0 0 #000;
  box-sizing: border-box;
  z-index: 10000 !important;
}

.react-simple-image-viewer__modal-content_Generico {

  /*background-color: #fff;*/

  margin: 50px auto 0px auto;
  text-align: center;
  justify-content: center;
  height: calc(100vh - 80px);
  width: calc(100vw - 70px);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-simple-image-viewer__modal-content_Generico img {
  width: calc(100vw - 800px);
  object-fit: cover;
  object-position: center center;
}

@media (max-width: 900px) {
  .react-simple-image-viewer__modal-content_Generico img {
    width: calc(100vw - 100px);
  }
}

.react-simple-image-viewer__modal-content div img {
  max-width: 600px;
}

.react-simple-image-viewer__close_Generico {
  color: white;
  position: absolute;
  top: 17px;
  margin-left: 23px;
  /*right: 30px;*/
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.react-simple-image-viewer__span_Generico {
  color: white;
  position: absolute;
  top: 20px;
  margin-left: 70px;
  /*right: 30px;*/
  font-size: 16px;
  font-weight: bold;
}

/*==========FIN*/



/*Image viewer references */
.react-simple-image-viewer__modal {
  z-index: 1;
  display: flex;
  align-items: center;
  position: fixed;
  /*
  padding: 155px 60px 0px 60px;
  */
  right: 0;
  top: 0;
  width: 900px;
  height: 100%;
  background-color: none repeat scroll 0 0 #000;
  box-sizing: border-box;
}


.react-simple-image-viewer__close {
  color: white;
  position: absolute;
  top: 17px;
  margin-left: 23px;
  /*right: 30px;*/
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.react-simple-image-viewer__span {
  color: white;
  position: absolute;
  top: 20px;
  margin-left: 70px;
  /*right: 30px;*/
  font-size: 16px;
  font-weight: bold;
}

.react-simple-image-viewer__modal-content {
  margin: auto;
  padding: 0;
  width: 90%;
  text-align: center;
  display: flex;
  justify-content: center;

}

.modalCoche {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  overflow: auto;
  /* Enable scroll if needed */
  margin-left: 40%;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
}

.box::-webkit-input-placeholder {
  color: #fff !important;
}

.box:-moz-placeholder {
  color: #fff !important;

}

.box::-moz-placeholder {
  color: #fff !important;
}


.boxCoche::-webkit-input-placeholder {
  color: #bfccd6 !important;
}

.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:active,
.dropup .dropdown-menu .dropdown-item:hover,
.dropup .dropdown-menu .dropdown-item:active,
.dropleft .dropdown-menu .dropdown-item:hover,
.dropleft .dropdown-menu .dropdown-item:active,
.dropright .dropdown-menu .dropdown-item:hover,
.dropright .dropdown-menu .dropdown-item:active {
  background-color: #c9c9c9 !important;
  color: #000;

}

.white-text,
.dropdown .dropdown-menu .dropdown-item:hover,
.dropdown .dropdown-menu .dropdown-item:active,
.dropup .dropdown-menu .dropdown-item:hover,
.dropup .dropdown-menu .dropdown-item:active,
.dropleft .dropdown-menu .dropdown-item:hover,
.dropleft .dropdown-menu .dropdown-item:active,
.dropright .dropdown-menu .dropdown-item:hover,
.dropright .dropdown-menu .dropdown-item:active,
.side-nav,
.side-nav .search-form .form-control,
.md-toast-message a,
.md-toast-message label,
.md-toast-close-button,
#toast-container>div,
.clockpicker_container.clockpicker .picker__box .clockpicker-am-pm-block .active,
.picker__list-item--selected,
.picker__list-item--selected:hover,
.picker--focused .picker__list-item--selected,
.picker--time .picker__button--clear:hover,
.picker--time .picker__button--clear:focus,
.picker--time .picker__button--clear:hover::before,
.picker--time .picker__button--clear:focus::before,
.picker__date-display,
.picker__date-display .clockpicker-display .clockpicker-display-column .clockpicker-span-hours.text-primary,
.picker__date-display .clockpicker-display .clockpicker-display-column .clockpicker-span-minutes.text-primary,
.picker__date-display .clockpicker-display .clockpicker-display-column #click-am.text-primary,
.picker__date-display .clockpicker-display .clockpicker-display-column #click-pm.text-primary,
.clockpicker-display .clockpicker-display-column .clockpicker-span-hours.text-primary,
.clockpicker-display .clockpicker-display-column .clockpicker-span-minutes.text-primary,
.clockpicker-display .clockpicker-display-column #click-am.text-primary,
.clockpicker-display .clockpicker-display-column #click-pm.text-primary,
.darktheme .picker__box .picker__date-display .clockpicker-display,
.darktheme .picker__box .picker__date-display .clockpicker-display .clockpicker-span-am-pm,
.darktheme .picker__box .picker__calendar-container .clockpicker-plate .clockpicker-tick,
.darktheme .picker__box .picker__footer button,
.picker__box .picker__header .picker__date-display,
.picker__box .picker__table .picker__day--outfocus,
.picker__box .picker__table .picker__day--selected,
.picker__box .picker__table .picker__day--selected:hover,
.picker__box .picker__table .picker--focused {
  color: #000 !important;
}


.mdb-skin input[type="text"]:focus:not([readonly]),
.mdb-skin input[type="password"]:focus:not([readonly]),
.mdb-skin input[type="number"]:focus:not([readonly]),
.mdb-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: #dcdcdc !important;
  box-shadow: 0 0px 0 0 #dcdcdc !important;
}

.estiloBuscadorOE:focus {
  border-style: none !important;
  outline: none !important;
}

/*Efecto botón*/
span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}


.mdb-skin .btn-default {
  background-color: #010910 !important;
  box-shadow: none !important;
}

.mdb-skin .btn-matricula {
  background-color: #eee !important;
  box-shadow: none !important;
}



.mdb-skin .btn-matriculav2 {
  background-color: transparent !important;
  box-shadow: none !important;
}

/*Solo para el icono del usuario*/
.nav-link {
  padding: .0rem 0rem !important;
}

.mdb-skin .btn-matriculav3 {
  background-color: #fff !important;
  box-shadow: none !important;
}


.navSup .nav li:first-child {
  margin-left: 0px !important;
}

.botonFinalMatricula {
  padding: 0px !important;
  background-color: #eee !important;
}



.icono-separacion :after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 60%;
  transform: translateY(-50%);
  background-color: #999999;
}

.selectorBuscadorMatricula {
  border: 0px !important;
  background-color: transparent !important;
}

.inputCoche {
  background-color: #eee;
  /* margin-left: .25rem !important;*/
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #eee;
}

.colorGris {
  background-color: #eee !important;

}


.input-group-prepend {
  margin-right: -4px;
}


.mdb-skin .btn-secondary {
  background-color: transparent !important;
  color: #000 !important;
  box-shadow: none !important;
}

.mdb-skin .boton-terciario {
  background-color: #eee !important;
  color: #000 !important;
  box-shadow: none !important;
}

.mdb-skin .boton-Coche {
  background-color: transparent !important;
  font-size: 14px;
  color: #333 !important;
  box-shadow: none !important;
  text-transform: none !important;
}



.mdb-skin .btn-secondary.dropCoche {
  background-color: transparent !important;
}

.mdb-skin .btn-secondary.dropCoche:hover {
  background-color: transparent !important;
}

.mdb-skin .btn-secondary.dropCoche:focus {
  background-color: transparent !important;
}

/*Cambio de color efecto azul dropDown*/
.mdb-skin .btn-secondary:not([disabled]):not(.disabled):active,
.mdb-skin .btn-secondary:not([disabled]):not(.disabled).active,
.show>.mdb-skin .btn-secondary.dropdown-toggle {
  background-color: transparent !important;
}

/*028047*/

.estilosbotonGrafico {
  cursor: pointer;
  background-color: #eee !important;
  border: none !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 60% !important;
}

.estilosbotonBucarTree {
  cursor: pointer;
  background-color: #eeeeee !important;
  border: none !important;
  width: 23px !important;
  height: 23px !important;
  border-radius: 60% !important;
}

.estilosbotonBucarTree:hover {
  background-color: #d8d7d7 !important;
}



.coloAzul {
  background-color: #000000 !important;
}


.mdb-skin .btn-coloAzulBajo {
  background-color: #1a3651 !important;
  box-shadow: none !important;
}

.colorSubAzul {
  color: #021323 !important;
}

.btn-buscador {
  background-color: #eee !important;
  box-shadow: none !important;
}

.botonRedondoDrawer {
  color: #fff;
  background-color: #010910;
  border: none;
  width: 41px !important;
  height: 40px !important;
}


/*
.active, .btnModal:hover {
  background-color: #666;
  color: white;
}
*/


.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #dcdcdc
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}


.centerMenu2 {
  margin: auto;
  width: 100%;
  padding: 13px;
  background-color: #dcdcdc;
  text-align: left;
}




/* Estilos para el drawer principal submenu*/
.flex-container {
  display: flex;
  flex-direction: row;
  font-size: 11px;
  text-align: center;
}

.flex-item-left {

  padding: 16px 5px;
  flex: 35%;
}

.flex-item-right {

  padding: 10px;
  flex: 50%;
}

@media (max-width: 600px) {
  .flex-container {
    flex-direction: column;
  }
}



/*Sistema grid*/
.grid-container {
  display: inline-flex;
  grid-template-columns: auto auto auto;
  /*margin-left: 10px;*/
  vertical-align: bottom;
  background-color: #eee;
  border-radius: 3px 3px 3px 3px !important;
  border: 0px solid #eee;
  padding: 2px 8px 2px 8px;
  height: 40px;

}

.grid-item {
  background-color: #eee;

  font-size: 16px;
  text-align: center;
}

.solorMargen {
  margin-top: 2px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #dcdcdc;
}


.barraLateral {
  background-color: #ccc;
  width: 2px;
  height: 28px;
  margin-top: 4px;
}

.estiloBotonRemove {
  border: 0 !important;
  padding: 6px 7px 5px 3px !important;
  margin: 0 !important;
}

.activebtnMenu2:hover {
  background-color: #eee !important;

}

.activebtnMenu3:hover {
  background-color: #000 !important;
}

.dropdown-menu {
  background-color: #dcdcdc !important;
}

.soloColorBlanco {
  color: #fff !important;
}






.soloColorNegro {
  color: #000 !important;
}


.colorDrawerMenu2 {
  color: #dcdcdc !important;
}

.backgroundNegro {
  background-color: #000 !important;
}


.backgrounNegroClaro {
  background-color: #2f2f2f !important;
}

.backgroundGris {
  background-color: #dcdcdc !important;
}


.text {
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  word-break: break-all;
  margin: 7px 5px;
}

.barraLateralDiagonal {
  background-color: #ccc;
  width: 2px;
  height: 28px;
  margin-top: 4px;
  transform: skew(-21deg);
}


.flex-container-Grow-Simple {
  display: flex;
  align-items: center;

}


.flex-container-Grow-Tercero {
  display: flex;
  align-items: center;
  background-color: #2f2f2f;
}

.flex-container-Grow-Tercero>div {
  color: #fff;
}




.flex-container-Grow {
  display: flex;
  align-items: center;
  background-color: #000;
}





.estiloContainerTree {
  margin-top: 7.8rem !important;
}

table.table td,
table.table th {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}



.spantitle {
  font-size: 16px;
}


.containerUl {
  position: absolute;
  width: 95%;
  text-align: left;

  z-index: 989;
  cursor: default;

  /*
  height: 100px;
  background-color: red;
  */
}

.containerFilroTree {
  position: absolute;
  /*width: 84%;*/
  width: 150%;
  text-align: left;
  z-index: 99999;
  background: #fff;
  box-shadow: 0 4px 6px rgb(32 33 36 / 28%);
  display: block;
  flex-direction: column;
  list-style-type: none;
  margin-left: 1px;
  margin-top: -5px !important;
  padding: 0;
  border: 0;
  border-radius: 0 !important;
  /* overflow: hidden; */
}


.containerFilroTree ul {
  list-style-type: none;
  list-style-position: outside;
  padding: 6px 0px 0px 18px;
}

.ulTree {
  flex: auto;
}

.li_tree {
  display: flex;
  align-items: center;
  min-width: 0;
  max-height: none;
  padding: 0;
  font-size: 14px;
}

.ulTree .li_tree:hover {
  background-color: #dcdcdc;
  color: #000 !important;
}


.containerReferencia {
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: auto;
  padding: 0px !important;
  padding-top: 5px !important;


}

.containerReferenciaItem {
  background-color: rgba(255, 255, 255, 0.8);
  /*border: 1px solid rgba(0, 0, 0, 0.8);*/
  padding: 0px;
  font-size: 16px;
  text-align: left;
}

.nombreArticulo {

  margin-bottom: 10px;
  border-bottom: 1px solid #2c3e50;
}

.containerReferencia .marcaArticuloTitulo {
  margin-bottom: 0;
  padding-left: 15px;
  font-size: 18px;



}


.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #ddd;
}


.pagination a.active {
  background-color: #8c8c8c;
  color: #fff;
  border: 1px solid #8c8c8c;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  ;
}

.pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.estiloTexto {
  font-size: 16px !important;
  color: #000 !important;
}

.columnaIcono {
  padding: 0px !important;
  vertical-align: middle;
}

.rowReferencia {
  padding: 5px 0px;
}

.rowReferencia td {
  padding: 5px 0px;

}

.rowReferencia:hover {
  background-color: #4285f4;
}


.breadcrumb-item+.breadcrumb-item::before {
  color: #fff !important;
}

.classic-tabs .nav li:first-child {
  margin-left: 0px !important;
}

.margenDiv {
  margin-top: 70px !important;
}

.estiloIcono {
  font-size: 22px;
}

.headerTabReferencia {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 5px !important;
}

.tab-content>.active {
  display: block;
}

.activeArcauto {
  opacity: 0.5 !important;
  cursor: pointer !important;
}


.containerSpinner {
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  align-items: 'center';
  text-align: 'center';
  min-height: '70vh'
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  display: block;
  float: none;
  margin: 0 0 16px 0;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 8px 8px 0px !important;
  text-align: left !important;
}



.svg-container {
  height: 100%;
  width: 100%;
  max-height: 15cm;
  max-width: 15cm;
  margin: 0 auto;
}


.menuGraficos {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0px;
  background-color: #fff;
  flex: 0 0 200px;

  max-width: 400px !important;
  min-width: 400px !important;
  width: 400px !important;
}

.estiloDrawerHeader {
  position: fixed;

}

.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  vertical-align: middle !important;
}


.ant-tree li {
  padding-bottom: 3px !important;
}





.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  padding: 2px;
  color: #666;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}



/* width */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}




.next-number-picker {
  display: inline-block;
}

.number-picker {
  position: relative;
  display: inline-block;
}

.product-quantity .product-quantity-info {
  margin-left: 8px;
  float: left;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 36px;
}

.product-warranty {
  margin-top: 18px;
}

.product-shipping {
  margin-top: 18px;
}




.next-input-group {
  display: inline-table;
  border-collapse: separate;
  border-spacing: 0;
  line-height: 0;
  width: 100%;
}

.zoro-ui-number-picker.next-number-picker .next-btn {
  border-radius: 50% !important;
  border: none;
  background: #f2f2f2;
  width: 30px;
  height: 30px;
}


.next-input-group-addon {
  width: 1px;
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
}

.next-input.next-medium input {
  height: 34px;
  text-align: center;
  font-size: 12px;
  border: transparent;
  font-size: 16px;
  font-weight: 500;
  margin: 5px 10px;
  width: 50px;
  border: 1px solid #dcdcdc;
}

.txtCantidad:focus {
  outline: none;
}

.paddindDeTres {
  padding: 3px !important;
}


.alertify-notifier {
  /*z-index: 99999999 !important;*/
  z-index: 99999999 !important;
}



.alertify-notifier .ajs-message.ajs-success {
  background-color: #4285f4;
}

div.container6 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagenMarketingSlider {
  width: 100%;
  height: 100%;
  display: block;
}

.efectoHome {
  opacity: 0.1;
  /*transform: translate3d(-3824px, 0px, 0px);*/
  transition: -webkit-transform 500ms ease 0s;
}

.homeImage:hover {
  filter: opacity(.5);
}



.ant-list-item-meta {
  padding-bottom: 7px !important;
  padding-top: 7px !important;
  padding-left: 7px !important;
}




.ant-list-item-action {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: -140px !important;
  padding: 0;
  font-size: 0;
  list-style: none;
}


.ant-list-item-action .vehiculoReferencia {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: -190px !important;
  padding: 0;
  font-size: 0;
  list-style: none;
}





.ant-list-item-meta:hover {
  background-color: #dddd !important;
  cursor: pointer;
}


.ant-tabs-tab {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #000 !important;
}

.orderTable {
  font-size: 14px !important;
  cursor: pointer !important;
  vertical-align: baseline;
  margin-left: 5px;
}

.orderTable:hover {
  color: #4285f4;

}

.anticon-search {
  vertical-align: baseline !important;
}










.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  visibility: hidden !important;
}





.drawer.impresion .page-content .sub-menu {
  height: 40px;
  background-color: #323639;
  display: flex;
  justify-content: space-between;
}

.sub-menu {
  background: #323639;
  padding: 5px;
}


.drawer.impresion .page-content .sub-menu .filename {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #fff;
  margin-left: 15px;
}

.drawer.impresion .page-content .sub-menu .actions {
  display: flex;
  align-items: center;
}

.drawer.impresion .page-content .sub-menu .actions button {
  background-color: initial;
  font-size: 1.3rem;
}


.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  line-height: 36px;
}



.conatinerTiempos>.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  line-height: 15px !important;
}

.conatinerTiempos>.ant-collapse>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box>.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  line-height: 15px !important;
}

.site-collapse-custom-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 0px !important;
  border-bottom: 0;
  border-radius: 2px;
}

.site-collapse-custom-panel {
  margin-bottom: 5px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px !important;
  border-radius: 2px;
  padding-bottom: 10px !important;
}


.site-collapse-custom-tareas {
  box-sizing: border-box;
  margin: 0;
  padding: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  background-color: #fff;
  /*border: 1px solid #d9d9d9;*/
  border-radius: 2px;
}


.site-collapse-custom-panel-manuales {
  margin-bottom: 5px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px !important;
  border-radius: 2px;
  padding-bottom: 10px !important;
  font-size: 20px;
  font-weight: 700;
}

.site-collapse-custom-tareas-manuales {
  box-sizing: border-box;
  margin: 0;
  padding: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  background-color: #fff;
  /*border: 1px solid #d9d9d9;*/
  border-radius: 2px;

}

.site-collapse-custom-tareas-manuales-subtitulo {
  font-size: 14px;
  font-weight: 500;
}

.site-collapse-custom-tareas-manuales-imagen {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.site-collapse-custom-tareas-manuales-imagen>img {
  height: 150px;
}


.included {
  position: absolute;
  color: #909090;
  line-height: 26px;
  font-size: 11px;
  font-weight: bold;
  background: #F0F0F0;
  display: block;
  width: 92px;
  height: 26px;
  margin: 0px 6px 0px 6px;
  text-align: center;
  text-transform: uppercase;
}

.followup {
  position: absolute;
  color: #39972e;
  line-height: 26px;
  font-size: 11px;
  font-weight: bold;
  background: #e2efe0;
  display: block;
  width: 92px;
  height: 26px;
  margin: 0px 6px 0px 6px;
  text-align: center;
  text-transform: uppercase;
}



.times {
  display: block;
  background: #fff;
  height: 80px;
  border-bottom: solid #dbdbdb 1px;
}

.times .jobs {
  display: block;
  height: 40px;
  width: 129px;
  border-left: solid #ededed 1px;
  float: left;
  margin: 20px 0px 0px 0px;
  color: #333;
}

.times .time {
  display: block;
  height: 40px;
  width: 129px;
  border-left: solid #ededed 1px;
  border-right: solid #ededed 1px;
  float: left;
  margin: 20px 0px 0px 0px;
  color: #333;
}

.parts {
  display: block;
  height: 40px;
  width: 129px;
  border-left: solid #ededed 1px;
  float: left;
  margin: 20px 0px 0px 0px;
  color: #333;
}

.times a.detail {
  float: left;
  margin: 28px 0px 0px 20px;
  line-height: 26px;
  font-size: 13px;
  font-family: 'Helvetica Neue', Arial;
  font-weight: bold;
  color: #006dc1;
}

.times .time .time-overview,
.times .jobs .jobs-count {
  font-family: 'Helvetica Neue', Arial;
  font-size: 20px;
  font-weight: bold;
  margin: 0px 20px 0px 20px;
}


.repair-times-detail {
  position: absolute;
  z-index: 999;
  display: block;
  height: 0px;
  overflow: hidden;
  background: #f6f6f6;
  width: 96%;
  padding: 0px 0px 0px 0px;
  border-bottom: none;
  margin-top: -24px;
}

.repair-times-detail.active {
  display: block;
  padding: 20px 0px 20px 0px;
  height: auto;
  min-height: 1px;
  border-bottom: solid #dbdbdb 1px;
  box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 20%);
}

.estilosbotonesModalV2 {
  cursor: pointer;
  border: none !important;
  width: 40px;
  height: 40px;
  border-radius: 60% !important;
}

.colorGris2 {
  background-color: #868585 !important;

}

.etiquetaNeumatico {
  border: 1px solid #999;
  padding: 5px;
  font-weight: bold;
  text-align: center;
  margin-right: 10px;
  margin-left: 5px;
}


.tbloem tbody tr:hover {
  background-color: #1790ff !important;
  color: #fff !important;
}

.tblcesta tbody tr:hover {
  background-color: #1790ff !important;
  color: #000 !important;
}


.flexContaineYQ {
  display: flex;
}

.flexContaineYQ>div {
  background-color: #f1f1f1;
  width: 50%;
  margin: 40px;
  text-align: center;
  line-height: 75px;
  font-size: 18px;
}



.image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, .5);
  cursor: pointer;
  opacity: 0;
  transition: opacity .3s;
  height: 200px;
}

.image-mask-info {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.image-mask:hover {
  opacity: 1;
}


.zoom {
  transition: 1.5s ease;
  -moz-transition: 1.5s ease;
  /* Firefox */
  -webkit-transition: 1.5s ease;
  /* Chrome - Safari */
  -o-transition: 1.5s ease;
  /* Opera */
}

.zoom:hover {
  transform: scale(2);
  -moz-transform: scale(2);
  /* Firefox */
  -webkit-transform: scale(2);
  /* Chrome - Safari */
  -o-transform: scale(2);
  /* Opera */
  -ms-transform: scale(2);
  /* IE9 */
}


.piezaImagenDocOE {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(150, 150, 150, 0.3);
}

.piezaImagenHover {
  border: 1px solid red;
}

.piezaTablaHover {
  background-color: rgba(150, 150, 150, 0.3);
}

.efectoContainerTelegram:hover {
  background-color: #323639 !important;
}

.efectoContainerVehiculo:hover {
  background-color: #495057 !important;
  color: #fff;
}

.tableReferencia td,
.table th {
  padding: 5px !important;
}











.fuenteTituloTexto {
  font-weight: 200;
  font-size: 16px;
  color: #053f75;
  text-transform: uppercase;

  padding-bottom: 20px;
}

.fuenteSubtituloTexto {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.fechaBoletin {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  background-color: #1890ff;
  padding: 10px;
  border-radius: 5px;
  margin-right: 20px;
}

.fuenteTituloItemTexto {
  font-weight: 500;
  font-size: 14px;
  color: #1890ff;
  text-transform: uppercase;

}

.tituloTxtBoletiones {
  font-size: 16px;
  color: #000;
  font-weight: 200;
  text-transform: uppercase;
}


.descripcionItem {
  font-weight: 300;
  font-size: 16px;
  color: #000;
  padding: 0px 10px 5px 10px;

}

.contenedorBuscadores {
  width: 100%;
  display: flex;
  flex-direction: row;
  /*flex-wrap:  wrap-reverse*/
  ;
  /*justify-content: flex-start;*/
}

.contenedorBuscadores>div {
  padding: 5px;
  margin: 0px 10px 0px 10px;

}

.contenedorBuscadores>div>div {
  margin-top: 5px;
}

.contenedorBuscadores>div:nth-child(1) {
  flex: calc(20% - 10px);

}

.contenedorBuscadores>div:nth-child(2) {
  flex: calc(20% - 10px);
}

.contenedorBuscadores>div:nth-child(3) {
  flex: calc(30% - 10px);
}

.contenedorBuscadores>div:nth-child(4) {
  flex: calc(30% - 20px);
}


@media (max-width: 900px) {
  .contenedorBuscadores {
    flex-direction: column;
  }
}

.contenedorImagen {
  border: 2px solid #dcdcdc;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.contenedorImagen>img {
  width: 200px;
  cursor: pointer;
}

.colorBloqueoBotoneraFacturacion {
  background-color: #f7f7f7 !important;
}

/*=======================*/






/*=================PARA LA PUBLICADAD DEL HOME=================*/

.ctPublicidad {
  display: flex;
  flex-direction: row;
  background: #303030;
  padding: 20px;
}

.soloCursor {
  cursor: pointer !important;

}

.ctPublicidad_item_1 {
  background-color: #000;
  padding: 20px;
  border-radius: 2px 0px;
  height: 100%;
  width: 65%;
  color: #fff;

  overflow: hidden;
}


.ctPublicidad_item_1>div>div>div>div>div>div>img {
  object-fit: cover !important;
  /*width: 100% !important;*/
}

/*
@supports(object-fit: cover){
  .ctPublicidad_item_1 > div > div > div > div > div > div > img{
    height: 100% !important;
    object-fit: cover !important;
    object-position: center center !important;
  }
}
*/


.ctPublicidad_item_2 {
  background-color: #fff;
  border-radius: 0px 2px;
  padding: 20px;
  height: 100%;
  width: 35%;
  display: flex;
  flex-direction: column;
}



.ctPublicidad_item_2_child_1 {
  height: 50%;
  width: 100%;
  margin-bottom: 10px;
  /*background-color: #053f75;*/
}

.ctPublicidad_item_2_child_1>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ctPublicidad_item_2_child_2 {
  height: 50%;
  width: 100%;
  margin-top: 10px;
}

.ctPublicidad_item_2_child_2>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slick-list {
  /*opacity: 0.8;*/
}

/*=================FIN PUBLICIDAD=================*/


.previaImagen {
  /*
  height: 55px;
  width: 55px;
  border-radius: 50%;
  background-color: red;
  margin-right: 10px;
  */
  position: relative;

  overflow: hidden;
  /*background-color: #646464;*/

}



.btn_flotante-telegram {
  background-color: #1890ff;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 28px;
  bottom: 100px;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  position: fixed;
  cursor: pointer;
}



.image-mask_telegram {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, .5);
  cursor: pointer;
  opacity: 0;
  transition: opacity .3s;
  height: 100%;
}

.image-mask-info_telegram {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;

}

.image-mask_telegram:hover {
  opacity: 1;
}

.image-mask-info_telegram>label {
  display: block;
  font-size: 16px;
  cursor: pointer;
}




.sub_Menu_Mensajes_Telegram {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #4e4e4e;
  color: #fff;

}


.ctSub_Menu_Telegram {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background: #4e4e4e;
  padding: 20px;
}

.ctSub_Menu_Telegram_item_1 {
  background-color: #000;
  border-radius: 2px 0px;
  height: 100%;
  width: 90%;
  color: #fff;
}

.ctSub_Menu_Telegram_2 {
  width: 10%;
  display: flex;
  justify-content: end;
  font-size: 35px;

  vertical-align: middle;
}

.ctSub_Menu_Telegram_2>span {
  line-height: 30px;
}




.Visor_Telegram {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #151515;
  z-index: 10000 !important;


}

.Visor_Telegram_Menu {
  background-color: #2f2f2f;
  height: 60px;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-size: 20px;
}


.Visor_Telegram_item_1 {
  width: 85%;
  color: #fff;
  height: inherit;
}

.Visor_Telegram_item_1>span:first-child {
  background-color: #080808;
  display: inline-flex;
  /*padding: 9px 20px 15px 20px;*/
  /*line-height: 36px;*/
  cursor: pointer;
  height: 60px;
  width: 60px;
  flex-direction: column;
  justify-content: center;

}

.Visor_Telegram_item_1>span:first-child:hover {
  background-color: #2f2f2f;
  cursor: pointer;

}

.Visor_Telegram_item_1>span:last-child {
  margin-left: 20px;
  vertical-align: middle;

}

.Visor_Telegram_item_2 {
  width: 15%;
  text-align: center;
  margin: 10px 40px;
  border-radius: 5px;
}

.Visor_Telegram_item_2>span {
  /*background-color: #4285f4;*/
  display: inline-block;
  justify-items: end;
  width: 100%;
  line-height: 40px;
  /*border-radius: 5px;*/
}

.Visor_Telegram_item_2>span:hover {
  /*background-color: #000;*/
  cursor: pointer;
}

.Container_doc_Visor_Telegram {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f5f5f5;
}

.Container_doc_Visor_Telegram>img {
  height: calc(100vh - 100px);
  object-fit: cover;
}



/*CARD PARA LOS DOCUMENTOS*/

.containerFicheros {
  margin: 0px 0px 20px 20px;
  display: flex;
  flex-wrap: wrap;
}

.containerFicheros_card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  margin: 10px 10px 0px 0px;
}


.containerFicheros_card_body {
  text-align: center;
  min-height: 1px;
  padding: 0.8rem;
  width: 140px;
  height: 140px;
  display: inherit;
  justify-content: center;
  align-items: center;
}

.containerFicheros_card_body>img {
  cursor: pointer;
  width: 90px;
  margin-left: auto;
  margin-right: auto;
}

/*=======FIN DE CARD DOCUMENTOS ======*/
#getFile {
  display: none;
}

#getFileLabel {

  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  margin: 10px 10px 0px 0px;

  text-align: center;
  min-height: 1px;
  padding: 0.8rem;
  width: 140px;
  height: 140px;
  display: inherit;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.btn_CP_Referencia {
  height: 35px;
  border: 0;
  border-radius: 3px;
  font-size: 13px;
  vertical-align: bottom;
  background-color: #ccc;
  margin-left: 10px;

}

.btn_CP_Referencia:hover {
  cursor: pointer;
  background-color: #eee;
}



/*============FAST=========*/
.container_fast {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  background-color: #fff;
  height: calc(100vh - 61px);
  padding: 10px;
  overflow: auto;
}

.item_fast {
  color: #fff;
  height: 260px;
  width: calc((100% / 4) - 20px);
  border-radius: 5px;
  margin: 10px 10px;
  background-repeat: no-repeat;
  background-size: 353px;
  background-position-x: -150px;
  background-position-y: 100px;
  border: 1px solid #dcdcdc;
}

.item_fast_header {
  background-color: #e5e5e5;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
}

.item_fast_header>h4 {
  font-size: 16px;
  color: #4f4f4f;
  font-weight: 500;
  margin: 0px;
}

.item_fast_header>img {
  height: 100px;
}

.item_fast_body {
  height: 100px;
  display: block;
  padding: 10px;
  height: calc(300px - 118px);
  font-size: 14px !important;
  color: #000;
  position: relative;
}

.item_fast_body_loader {
  background-color: #ccc;
  opacity: 0.2;
}


.item_fast_body_loader_mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, .5);
  cursor: pointer;
  opacity: 0;
  opacity: 1;
  height: 100%;
}

.item_fast_body_loader_mask_image {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;

}

.item_fast_body_container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  /*height: calc(300px - 109.5px);*/
}

.item_fast_body_container_item_botones {
  padding: 0px 5px 5px 5px;
  background-color: #999;
  color: #fff;
  display: inline;
  margin-left: 10px;
  border-radius: 2px;
  cursor: pointer !important;
}

.item_fast_body_container_item_botones>a>span {
  color: #fff;
  cursor: pointer !important;
}


.item_fast_body_container_item .referencia {
  color: #4285f4;
  font-size: 16px;

}

.item_fast_body_container_item .marca {
  font-size: 18px;
  text-transform: uppercase;
}

.texto_resaltado {
  font-size: 16px;


}

.texto_resaltado>span:first-child {
  color: #999;
}

.item_fast_footer {
  display: flex;
  align-content: flex-start;
  /*flex-wrap: wrap;*/
  border-radius: 0px 0px 5px 5px;
  overflow: auto;
  background-color: #fff;
  border-top: 1px solid #dcdcdc;
}

.item_fast_footer_item {
  color: #00000073;
  cursor: pointer !important;
  padding: 5px;
  /*width: calc((100% / 3) - 8px);*/
  text-align: center;
}

.item_fast_footer_item:hover {
  color: #4285f4;
  cursor: pointer !important;
}

.item_fast_footer_item>span {
  font-size: 20px;
}

.item_fast_footer_item:first-child {
  border-radius: 0px 0px 0px 1px
}

.item_fast_footer_item:last-child {
  margin: 0px;
}

.item_fast_footer_barraLateral {
  background-color: #ccc;
  width: 2px;
  height: 28px;
  margin-top: 4px;
  margin: 4px 2px;
  vertical-align: middle;
}

.btn_fast_presupuesto {
  background-color: #f7f7f7;
  color: #666;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px;
  text-transform: uppercase;
  margin-top: 2px;
  float: right;
  cursor: pointer !important;
}

.btn_fast_presupuesto:hover {
  cursor: pointer !important;
  /* background-color: rgb(28, 28, 28);*/
}

.estilo_tiempo {
  color: #666;
}

.loader_container_fast {
  width: 20%;
  vertical-align: middle;
}

.disabled_container {
  opacity: 0.5;
  pointer-events: none;
}

/*============FIN FAST=========*/




/*===============MANUALES DE REPARACIÓN============*/


.container_manuales {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  background-color: #fff;
  height: calc(100vh - 61px);
  padding: 10px;
  overflow: auto;
}

.item_1_manuales {
  background-color: rgba(232, 230, 230, 0.867);
  height: 100%;
  width: 30%;
}

.item_2_manuales {
  background-color: #fff;
  height: 100%;
  width: 70%;
  overflow: auto;
  overflow-x: hidden;
}

.manual_select {
  padding: 10px;
}

.manual_listado {
  padding: 0px 10px;
  height: calc(100% - 70px);
  overflow: auto;
}

.manual_listado>div {
  background-color: #dadada;
  padding: 10px;
  margin: 5px 0px;
  border-radius: 5px;
  border: #acacac 1px solid;
  color: #000;
  cursor: pointer !important;
  width: 100%;

}

.manual_listado>div:hover {
  background-color: #b2b2b2;
  cursor: pointer !important;
}


.manual_listado>a {
  background-color: #dadada;
  padding: 10px;
  margin: 5px 0px;
  border-radius: 5px;
  border: #acacac 1px solid;
  color: #000;
  cursor: pointer !important;
  width: 100%;
  display: block;
}

.manual_listado>a:hover {
  background-color: #b2b2b2;
  cursor: pointer !important;
}

.PARAGRAPH_STYLE {
  font-size: 14px;
  line-height: 18px;
  padding: 8px 0 8px 0;
  overflow: hidden;
  position: relative;
  border-bottom: none;
}


/*===============FIN MANUALES DE REPARACIÓN============*/



/*===============FICHA COBROS DE LA FACTURA ===========*/
.container_cobros_fact {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  height: calc(100vh - 61px);
  padding: 10px;
  overflow: auto;
}


.item_cobros_fact {
  color: #000;
  height: 260px;
  width: 100%;
  border-radius: 5px;
  margin: 10px;
  border: 1px solid #dcdcdc;
  padding: 0px 0px 10px 0px;
  font-size: 16px;
  background-color: #b8b8b844;
}

.header_cobros {
  background-color: #a1a1a1;
  margin-bottom: 10px;
  display: flex;
  padding: 5px 10px;
  border-radius: 5px 5px 0px 0px;
}

.header_cobros>div {
  margin: 0 0 0 auto;
}

.header_cobros>div>span {
  font-size: 25px;
  color: #fff;
  cursor: pointer !important;
}

.obs_cobro {
  overflow: auto;
  height: 85px;
}

.container_Pag_Fact {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 5px 10px;
}

.item_container_Pag_Fac_25 {
  width: 25%;
}

.item_container_Pag_Fac_50 {
  width: 50%;
}

.item_container_Pag_Fac_75 {
  width: 75%;
}

.btn_imp_cobro {
  padding: 4px;
  background-color: #848484;
  float: right;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

.text_secundario {
  color: #717070;
}

/*===============FIN FICHA COBROS DE LA FACTURA ===========*/



.card_pay {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  background-color: #dcdcdc;
  border-radius: 5px;
  padding: 10px 10px 0px 10px;


}

.card_pay_item_2 {
  padding: 0px 0px 0px 10px;
  width: 70%;
}

.card_pay_item_2_control {
  margin: 0px 0px 10px 0px;
}

.card_pay_item_2_control .btn_pago_modal {
  background-color: #1890ff;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  width: 50%;
  margin: 0 0 0 auto;
  cursor: pointer !important;
  border-radius: 5px;
}


.card_pay_item_1 {
  display: flex;
  align-content: flex-start;
  /*background-color: #006dc1;*/
  flex-direction: column;
  padding: 0px 0px 0px 0px;
  width: 30%;
  border-radius: 5px;
}

.card_pay_item_1>div {
  text-align: center;
  margin: 0px 0px 10px 0px;
  background-color: #d8d7d7;
  height: calc((100% / 3));
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #000;
  background-repeat: no-repeat;
  background-size: 290px;
  background-position-x: -100px;
  /*background-position-y: 10px;*/
}


.card_pay_item_1>div:hover {
  background-color: #1890ff;
  cursor: pointer !important;
}

.hover_card_pay_item_1 {
  background-color: #1890ff !important;
  cursor: pointer !important;
}


.form_input_Pago>input {
  width: calc((100% / 2) - 5px);
}

.form_input_Pago>input:first-child {
  margin-right: 10px;
}



/*=========ABONOS =========*/


.container_checked_abonos {
  padding: 6px;
}

/*
.slick-active > button{
  height: 10px !important;
  width: 40px !important;
}
*/
.slick-dots>li>button {
  height: 10px !important;
  width: 50px !important;
  border-radius: 4px !important;
  background-color: #4285f4 !important;
}


.continaerVideo {

  height: calc(100vh - 56px) !important;
  overflow: auto;
  overflow-x: hidden;
  padding: 20px;

  background-color: #dcdcdc;
}

.continaerVideo>video {
  width: calc(100% - 5px);
}

.continaerVideo>h4:first-child {
  margin-top: 0px;
  margin-bottom: 20px;
}

.continaerVideo>h4 {
  font-size: 22px;
  font-weight: 600;
  margin: 20px 0px;
}

.textoidentificador {
  margin-top: 10px;
}

.tituloIdentificador {
  font-weight: 600;
  font-size: 16px;
}

.subtituloIdentificador {
  font-weight: 600;
  font-size: 12px;
  color: #999;
}

.containerCestaAlmacen {
  height: calc(100vh - 575px);
  overflow: auto;
}

.containerCestaAlmacenSinIdentificador {
  height: calc(100vh - 415px);
  overflow: auto;
}

.color_btn_importadoras {
  background-color: #002857 !important;
}

.color_btn_wop_gpn {
  background-color: #008abd !important;
}

.color_btn_sertec_default {
  background-color: #000 !important;
}

.btn_iq_buscar_vehiculo_bloqueo {
  background-color: #f5f5f5 !important;
  color: #dcdcdc !important;
  pointer-events: none !important;
}

.btn_iq_buscar_vehiculo {
  padding: 5px 10px;
  font-size: 16px;


  background-color: #1890ff;
  color: #fff;
  width: 300px;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  cursor: pointer !important;
}

.btn_iq_buscar_vehiculo:hover {
  opacity: 0.5;
}

.Container_vehiculo_IQ {
  font-size: 16px;
  color: #000;
  padding: 20PX;
}

.container_tabla_vehiculo_IQ {
  height: calc(100vh - 169px);
  overflow: auto;
}

.mas_info_vehiculo_iq {
  font-size: 16px;
  max-width: 600px;
}

.mas_info_vehiculo_iq>div {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
  color: #000;
  font-size: 16px;
  align-items: center;
  text-transform: uppercase;
}

.mas_info_vehiculo_iq>div>p {
  margin: 0px;
  font-size: 15px;
  color: #858585;
  font-weight: 600;
}

.tituloLeyendaVehiculo>p {
  font-size: 20px !important;
  font-weight: 400;
}



.containerAjustes {
  padding: 0px 20px;
  background-color: #fff;
  list-style: none;

}




.Subtitulo_submenu {
  color: #1890ff;

  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  padding: 8px 0 8px 0;
  overflow: hidden;

  border-bottom: solid #dadada 1px;
}

.listaAjustes {
  font-size: 14px;
  line-height: 18px;
  padding: 8px 0 8px 8px;


}



.value {
  right: 151px;
  width: 200px;
  border: none;
}

.unit {
  right: 0;
  width: 150px;
  border: none;
}

.tabla_ajuestes>tbody>tr {
  border-bottom: solid #dadada 1px;
}

.tabla_ajuestes>tbody>tr:hover {
  background-color: transparent !important;
  cursor: default !important;
}

.tabla_ajuestes>tbody>tr>td {
  padding: 6px;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.hederTituloAjustes {
  font-size: 20px;
  font-weight: 600;
}

/* ESTILOS FICHA AJUSTES*/

.layout_Ajustes {
  padding: 20px;
  display: flex;
}

.menu_ajustes {
  flex: 30%;
  height: calc(100vh - 101px) !important;
  overflow: auto !important;
  background-color: rgba(232, 230, 230, 0.867);
  padding: 10PX 0PX;
}

.informacion_ajustes {
  flex: 70%;
  height: calc(100vh - 101px);
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.btn_buscar_neumatico_ajustes {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dcdcdc;
  color: #1890ff;
  gap: 10px;
  border-radius: 5px;
  cursor: pointer !important;
}

.btn_buscar_neumatico_ajustes>span {
  font-size: 14px;
}

/******************************************* DISEÑO FICHA DE NEUMATICOS **********************************************/


.menu_filtros_neumaticos {
  padding: 10px 20px;
  background-color: #dcdcdc;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.menu_filtros_neumaticos_1 {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

}

.menu_filtros_neumaticos_1>div {
  cursor: pointer !important;
}

.menu_filtros_neumaticos_1>div>span {
  font-size: 25px;
  color: #000;
}


.menu_filtros_neumaticos_2 {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}


.tabla_catalogo_neumatico>thead>tr>th {
  border-bottom: 1px solid #d9d9d9;
  padding: 5px;
  color: #000;
  font-weight: 600;

}

.tabla_catalogo_neumatico>tbody>tr {
  border-bottom: solid #dadada 1px;

}

.tabla_catalogo_neumatico>tbody>tr:hover {
  background-color: #f7f7f7 !important;
  cursor: default !important;
}

.tabla_catalogo_neumatico>tbody>tr>td {
  padding: 6px;
  font-size: 16px;
  color: #666;
  font-weight: bold;
  cursor: pointer !important;
  vertical-align: initial;
}




.layout_neumaticos {
  padding: 20px;
}




.container_tabla_neumatico {
  overflow-y: auto;
}

.paginador_tabla {
  padding-top: 20px;
  display: flex;
  justify-content: right;
}

.paginador_tabla>ul {
  margin: 0px !important;
}




.container_lista_medidas {
  position: absolute;
  text-align: left;
  z-index: 99999;
  background: #fff;
  box-shadow: 0 4px 6px rgb(32 33 36 / 28%);
  display: block;
  flex-direction: column;
  list-style-type: none;
  margin-left: 1px;
  padding: 0;
  border: 0;
  border-radius: 0 !important;
}


.container_lista_medidas ul {
  list-style-type: none;
  list-style-position: outside;
  padding: 10px;
}

.ulTree_mediddas {
  flex: auto;


}

.li_tree_medidas {
  display: flex;
  align-items: center;
  min-width: 0;
  max-height: none;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  /*
  margin: 10px;
  padding: 10px;
  */
  padding: 10px;
  border: 1px solid #dcdcdc;
}

.li_tree_medidas:hover {
  background-color: #dcdcdc;
}

.li_tree_medidas>div {
  display: flex;
  align-items: center;
  gap: 10px;
}


.li_tree_medidas>div>span {
  font-size: 15px;
  color: #929292;
}

.info_medidas {
  color: #000;
  padding: 10px 20px;
}

.info_medidas>p {
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  margin: 5px 10px;

}

.ulTree_mediddas .li_tree:hover {
  background-color: #dcdcdc;
  color: #000;
}



.medidas_neumaticos {
  border: 1px solid #dcdcdc;
  border-radius: 5px;

}


.container_medidas_seleccionada {
  display: flex;
  gap: 10px;
  background-color: #fff;
  align-items: center;
  padding: 3px 10px;
  border-radius: 4px;
  border-radius: 1px solid #d9d9d9;
}

.item_medidas_seleccionada {
  display: flex;
  align-items: center;
}

.item_medidas_seleccionada>div {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #000;
  padding: 2px 5px;
  margin: 2px;
  background-color: #f2f2f2;
  font-size: 12px;
  font-weight: 400;
}

.item_medidas_seleccionada>div>span {
  font-size: 14px;
  color: rgb(150, 150, 150);
  cursor: pointer !important;

}

.btn_medidas>span {
  font-size: 18px;
  color: rgb(150, 150, 150);
}

.container_info_neumatico {
  display: flex;
  gap: 5px;
  padding: 5px 0px;
}

.container_info_neumatico>img {
  height: 30px !important;
}

.etiquetaNeumatico_info {
  padding: 0px 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #000;
}


.txt_runflat {
  color: #333 !important;
  background-color: #dcdcdc;
  border-radius: 4px;
  padding: 1px 5px;
  margin-right: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 400;
}



.imagen_tabla_neumatico>img {
  height: 48px;
}

.container_acciones_tabla {
  display: flex;
  gap: 15px;
  margin: 0px 0px 0px 10px;
  align-items: center;
}

.container_acciones_tabla>div>span {
  font-size: 22px;
  vertical-align: text-top;
}

.container_acciones_tabla>div>span:hover {
  color: #aaaaaa;
}


.conatinerDetalleFicha {
  height: calc(100vh - 153px);
  overflow-y: auto;
}


.layout_ficha_detalle_neumatico {
  display: flex;
  height: calc(100vh - 61px);
  overflow: auto;
  gap: 20px;
  padding: 20px;
}


.layout_datos_neumatico {
  flex: 80%;
  height: 100%;
  overflow-y: auto;


}

.layout_compra_neumatico {
  flex: 20%;
  color: #000;
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 5px;
}

.layout_compra_neumatico>div {
  margin-bottom: 10px;
}




.tabla_generica_detalle>thead>tr>th {
  /*border-bottom: 1px solid #d9d9d9;*/
  padding: 5px;
  color: #000;
  font-weight: 600;
  font-size: 20PX;
}

.tabla_generica_detalle>tbody>tr:hover {
  background-color: transparent !important;
}

.tabla_generica_detalle>tbody>tr>td {
  padding: 42x 5px;
  font-size: 14px;
  color: #000;
  cursor: default !important;
  font-weight: 500;
}

.txt_resgistro_tabla {
  font-weight: 400;
}


.txt_enlace {
  list-style: none;
  padding: 0px;
  margin: 0px;
  color: #1790ff;

}

.txt_enlace>li {
  margin-bottom: 5px;
  text-decoration: underline;
  cursor: pointer !important;
}

.txt_enlace>li:hover {
  color: #3a9efb;
}



.sp_titulo_neumatico {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}


.sp_subtitulo_neumatico {
  font-size: 19px;
  font-weight: 400;
  display: block;

}

.layout_imagenes_neumaticos {
  display: flex;
  gap: 10px;
  width: 880px;
  overflow-y: auto;
}



.layout_imagenes_neumaticos>div {
  border: 1px solid #dcdcdc;
  padding: 10px;
}

.layout_imagenes_neumaticos>div>img {
  height: 150px;
  cursor: pointer !important;
}




.acerca_producto_titulo {
  font-size: 16px;
  font-weight: 700;
}

.acerca_producto_descripcion {
  color: #000;
  font-size: 14px;
  padding: 0px 20px;
}

.ver_mas_info {
  font-size: 14px;
  color: #007185;
  text-decoration: underline;
  cursor: pointer !important;
}

.ver_mas_info:hover {
  color: #856400;

}

.informacion_neumatico {
  display: flex;
  gap: 10px;
}

.informacion_neumatico_item1 {
  flex: 100%;
}

.informacion_neumatico_item2 {
  flex: 0%;
}

.marketing_producto_titulo {
  font-size: 16px;
  font-weight: 700;
}

.descripcion_producto_titulo {
  color: #000;
  font-size: 13px;
}

.descripcion_producto_titulo>p {
  margin: 0px 0px 2px 0px;
}


.txt_resgistro_tabla_titulo {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #333 !important;
}

.txt_resgistro_tabla_precio {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.container_catidad_compra {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container_catidad_compra>div:first-child {
  font-size: 18px;
  font-weight: 600;
}

.botonGenerico {
  border: 0;
  border-radius: 4px;

  font-size: 16px;
  font-weight: 200;
  color: #000;
  /*background-color: #efefef;*/
  background-color: #F0F0F0;
  cursor: pointer;
  padding: 8px 10px;
  display: flex;
  align-items: center;
}

.botonGenerico>span {
  margin-right: 5px;
  font-size: 20px;
}

.visor_imagen_generica {
  /*height: calc(100vh - 100px);*/
  height: calc(100vh - 160px);

}


.iframe_generico {
  height: calc(100vh - 66px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}



.container_visor_tecnico {
  height: calc(100vh - 61px);
  background-color: #333;
}

.eventos_visor {
  display: flex;
  gap: 10px;
  justify-content: end;
  background-color: rgb(103, 103, 103);
  padding: 0px 20px;
}

.eventos_visor>button>span {
  font-size: 24px !important;
}

/*
.container_visor_tecnico div{
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
*/
.container_visor_tecnico div h3 {
  color: #fff;
  padding: none;

}


.iframe_Doc {
  width: 100%;
  height: calc(100% - 55px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}

#end {
  display: none;
}









.container_neumatico_sin_coche {
  display: grid;
  gap: 30px;
}

.titulo_neumatco_sin_vehiculo {
  font-weight: 300;
  margin: 0;

}

.imagenes_medidas_coche {
  display: flex;
  justify-content: center;
}

.imagenes_medidas_coche>img {
  width: 100%;
}

.container_btn_neumatico_iniciar {
  display: flex;
  justify-content: center;
}

.btn_neumatico_iniciar {
  background-color: rgb(30, 58, 131);
  padding: 10px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  cursor: pointer !important;
  width: 50%;
}

.btn_neumatico_iniciar:hover {
  background-color: rgb(40, 77, 170);

}

.container_btn_filtro_neumatico {
  border-radius: 4px;
  display: flex;
}

.btn_limpiar_filtros {
  background-color: rgb(40, 77, 170);
  padding: 7px 10px;
  color: #fff;
  border-radius: 4px 0px 0px 4px;
  cursor: pointer !important;

}

.btn_buscar_filtros {
  background-color: #fff;
  padding: 7px 10px;
  color: #000;
  border-radius: 0px 4px 4px 0px;
  cursor: pointer !important;


  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 5px;
}

.btn_limpiar_filtros:hover {
  background-color: rgb(38, 66, 135);
}

.btn_buscar_filtros:hover {
  background-color: #f2f2f2;
}



.container_btn_filtro_neumatico>div>span {
  font-size: 22px;
}

.descripcion_neumatico {
  color: #0f5392 !important;
  background-color: transparent;
  font-size: 22px;
  font-weight: 600;
}

.pedido_final_item2 {
  display: flex;
  justify-content: end;
  align-items: center;
}

.pedido_final_item2 {
  gap: 20px
}



.btn_buscador_tarifa {
  color: #666666;
  padding: 0px 10px;

  height: 100%;
  border: none;
  display: flex;
  align-items: center;
  margin: 0px 2px;
}

.bordeContainer {
  border-radius: 5px !important;
}

.containerRMIFluidos {
  display: flex;
  height: calc(100vh - 61px);
  padding: 40px;
  color: #000;
}

.item_rmi_fluidos {
  height: 100%;
  overflow: auto;
}

.center_item_alert {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}





#pageBody>h1 {
  font-weight: bold;
  font-size: 2em;
}

/*
#pageBody>table>tbody>tr:hover {
  background-color: rebeccapurple !important;
}
*/

#pageBody>table tbody tr:hover {
  background-color: transparent !important;
  color: #000 !important;
}

.b8_N>td {
  font-weight: bold !important;
}

.b8_A>td {
  font-weight: bold !important;
}



.containerRMIMantenimientos {
  display: flex;
  padding: 20px;
  overflow: auto;
  gap: 20px;
  height: calc(100vh - 61px);
}

.item_1_MantenimientosRMI {
  background-color: #f2f2f2;
  height: 100%;
  width: 25%;
}


.item_2_MantenimientosRMI {
  background-color: #fff;
  width: 365px;
}

.item_3_MantenimientosRMI {
  flex: 1;
}


.containerRMIManuales {
  display: flex;
  padding: 20px;
  overflow: auto;
  gap: 20px;
  height: calc(100vh - 61px);
}



.item_1_manualesRMI {
  /* background-color: rgb(238, 238, 238);*/
  background-color: #fff;
  height: 100%;
  width: 30%;
}


.item_2_manualesRMI {
  background-color: #fff;
  width: 70%;


}

.manual_listado_rmi>div {
  border-radius: 0px;
}

.lista_secudario_manuales {
  border: 1px solid #dcdcdc;
  color: #000;
  height: 100%;
  overflow: auto;
}

.lista_secudario_manuales>div {
  padding: 10px 20px;
  border-bottom: 1px solid #dcdcdc;
  cursor: pointer !important;
  font-size: 18px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.header_manual_html {
  background-color: rgb(103, 103, 103);
  display: flex;
  justify-content: end;
  gap: 10px;
  padding: 10px 20px;
}

.header_manual_html>button {
  border: none;
  padding: 10px 15px;
  background-color: rgb(103, 103, 103);
}

.header_manual_html>button:hover {

  background-color: rgb(95, 95, 95);
}

.header_manual_html>button>span {
  font-size: 28px;
}


.efecto>span {
  font-size: 14px;
}

.lista_secudario_manuales>.efecto:hover {
  background-color: #1890ff;
  color: #fff;
}


.lista_secudario_manuales_active {
  background-color: #1890ff;
  color: #fff;
}

.item_nivel2_manual {
  padding: 10px 0px 10px 60px !important;
  width: 100%;
}


.lista_secudario_grupo_manual {
  color: #000;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.titulo_grupo_manual {
  font-size: 16px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #999797;
}

.titulo_grupo_manual>span {
  font-size: 12px;
}

.item_manual_ver {
  padding: 0px 50px;
  font-size: 14px;
  margin-bottom: 10px;
  color: #1890ff;
  cursor: pointer !important;

}

.item_manual_ver:hover {
  color: #1374cf;
  font-weight: 600;
}

.manual_modal_body {
  height: calc(100vh - 120px);
  overflow: auto;
}

.calcular_altura_modal_rmi {
  min-height: calc(100vh / 1.2);
}

.containerModalTitulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.containerModalTitulo>div:last-child {
  padding-right: 40px;

}


.btnPrintRMI {
  border: 0;
  background-color: #1374cf;
  color: #fff;
  padding: 5px 10px;
}

.btnPrintRMI>span {
  font-size: 20px;
}

.calcular_altura_manual {
  height: calc(100% - 68px);
  background-color: rgb(82, 86, 89);
  padding: 20px;
  border: none;
}

.calcular_altura_manual>div {
  background-color: #fff;
  padding: 20px;
}

.ifr_rmi_data {
  height: calc(100vh - 61px);
  padding: 10px;
}

.containerDataAjustes {
  display: flex;
  justify-content: space-between;
}

.containerDataAjustes>div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.containerDataAjustes>div:first-child>span {
  font-size: 14px;
}

.containerDataAjustes>div:last-child>span {
  font-size: 27px;
}

.containerDataAjustes>div:last-child:hover {
  color: #0f5496;
}

.pos_trabajo {
  padding-left: 20px;
}

.sin_padding {
  padding: 0px;
}

.container_item_manual {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #000;
  padding-top: 12px;
  margin: 0px;
}

.item_manual_2 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.btnColorCestaAdd {
  font-size: 22px;
  color: green !important;
}

.btnColorCestaRemove {
  font-size: 22px;
  color: red;
}

.cab_titulo_mantenimiento {
  border: 1px solid #b1afaf;
  color: #000;
  background-color: rgb(217, 217, 217);
}

.containerStockGenerico {
  margin: 20px;
  height: calc(100vh - 101px);
  overflow: auto;
}

.containerArticuloGenerico {
  background-color: rgb(247, 247, 247);
  height: calc(100vh - 61px);
  padding: 20px;
}

.containerDisponibilidadGenerico {
  background-color: rgb(247, 247, 247);
  padding: 20px;

}

.ContainerDetalles {
  width: 100%;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  min-height: 100px;

  display: flex;
  gap: 20px;
  color: #000;
  padding: 20px;
  margin-bottom: 20px;
}

.grupoImagenReferencia>img {
  width: 100px;
  height: auto;
}

.infoDetalleReferencia>div {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 600;
  text-transform: uppercase;
}

.infoDetalleReferencia>div>span {

  font-weight: 400;
}

.grupoBtnArticulo {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 20px;

}


.label_entrega {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label_entrega>span:first-child {
  font-size: 22px;
  color: #f3a842;
}

.container_item1_car {
  display: flex;
  align-items: center;
}

.container_item1_car>img {
  margin: 20px 20px 20px 20px;
  height: 110px;
}

.containermycar {
  margin: 20px 0px 20px 20px;
}

.txtprecio_personal {
  font-size: 16px !important;
}

.txtprecio_personal>span {
  font-size: 16px;
}

.btn_bolsas {
  position: absolute;
  height: inherit;
  display: flex;
  align-items: center;
  width: 400px;
  justify-content: center;
}

.btnDesglesGrupo {
  border: none;
  padding: 2px 10px;
  color: #1374cf;
  background: transparent;
}

.herramientasAdicionales {
  display: flex;
  justify-content: center;
}

.herramientasAdicionales>button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgb(240, 240, 240);
  color: #1374cf;
  margin-top: 10px;
  padding: 0px 20px;
  height: 40px;
  font-weight: 600;
  /*border: 1px solid #c0c0c0;*/
  border: none;
  width: 100%;

}

.herramientasAdicionales>button>span {
  font-size: 22px;
}


.containerRMITiempos {
  display: flex;
  padding: 20px;
  overflow: auto;
  gap: 20px;
  height: calc(100vh - 138px);
}


.item_1_TiemposRMI {
  background-color: #fff;
  width: 30%;
  overflow: auto;

}


.item_2_TiemposRMI {
  width: 70%;
  overflow: auto;
  padding-right: 20px;
}


.container_listado {
  /* border: 1px solid #dcdcdc;*/
  color: #000;
  height: 100%;
  margin-right: 10px;
}

.container_listado>ul {
  color: #000;
  list-style: none;
  padding: 0;
  width: 100%;

}

.container_listado>ul>li {

  min-height: 28px;
  /*line-height: 28px;*/
  padding-left: 24px;
  color: #000;
  font-size: 14px;
  border-right: 1px solid #dcdcdc;
  display: flex;
  align-items: center;

}


.container_listado>ul>li:hover {
  color: #1890ff !important;
}

.item_titulo_listado {
  cursor: not-allowed !important;
  font-size: 16px !important;

}

.item_listado {
  color: #868585 !important;
  cursor: pointer !important;

}

.item_listado_seleccionado {
  background-color: #e6f7ff;
  border-right: 3px solid #1890ff !important;
  color: #1890ff !important;
  transition: ease-in 0.1s
}

.item_tiempo {

  width: 100%;
  color: #000;
  background: #f7f7f7;
  /*background-color: #dcdcdc;*/
  cursor: pointer !important;
  margin-bottom: 2px;
}



.item_tiempo_item {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 49px;
  padding: 0px 20px;

}

.sub_item_tiempo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  width: 100%;

}

.sub_item_tiempo>div:first-child {
  flex: 1;
}

.sub_item_tiempo>div {
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}


.sub_item_tiempo_desglose {
  display: flex;
  padding: 6px 5px 6px 30px;
  align-items: center;
  justify-content: space-between;
  color: #999696;
}


.container_tiempo_pieza {
  background-color: #f2f2f2;
  padding: 10px 5px 10px 10px;
  margin: 5px 0px 5px 20px;
}

.container_tiempo_pieza>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  padding: 8px 0px;

}

.container_articulo_tiempo {
  min-height: 250px;
  margin-bottom: 20px;
}


.item_articulo_tiempo {
  border: 1px solid #dcdcdc;
  background-color: #f8f9fa;
  padding: 10px;
}

.articulo_tiempo div {
  margin-bottom: 10px;
}


.item_articulo_tiempo>div>label {
  font-size: 14px;
  display: block;
  padding: 5px 0px;
}

.btn_articulo_tiempos {
  display: flex;
  align-items: center;
  justify-content: end;
}

.btn_articulo_tiempos>button {
  border: none;
  padding: 8px 15px;
  background-color: #1890ff;
  color: #fff;
  border-radius: 2px;
}

.headerTiempoRMI {
  padding: 20px 30px 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;

}

.item1_tiempoRMI {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.item2_tiempoRMI {
  display: flex;
  align-items: center;
  gap: 100px;
}

.item2_tiempoHaynes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex: auto;

}

.item2_tiempoHaynes_container {
  display: flex;
  gap: 40px;

}

.item2_tiempoRMI>div>span:first-child {
  font-size: 18px;
  font-weight: 400;
}

.item2_tiempoRMI>div>span:last-child {
  font-size: 20px;
  font-weight: bold;
}

.item2_tiempoRMI>div:last-child>span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  color: #1890ff;
}


.btn_detalles_lineas_active {
  background: '#f6f6f6';
  border: none;
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid rgb(219, 219, 219);
  font-size: 18px;
  padding: 5px 10px;
}

.btn_detalles_lineas_active>span {
  font-size: 22px;

}

.container_lineas {
  display: flex;
  padding: 10px;
  margin: 5px 20px;
  align-items: center;
  justify-content: space-between;
  color: #000;
  background-color: #fff;

}

.item2_cesta {
  display: flex;
  gap: 20px;
}

.buscar_referencia_tecrmi {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  cursor: pointer !important;
}

.buscar_referencia_tecrmi span {
  font-size: 20px;
}


.modal__header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1d5db;
}

.modal__footer {
  display: flex;
  justify-content: flex-end;

  border-top: 1px solid #d1d5db;
}




.card_mantenimiento {

  border: 1px solid #dcdcdc;
  color: #000;
  border-radius: 2px;
  margin-bottom: 20px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0/14%), 0 1px 5px 0 rgb(0 0 0/12%), 0 3px 1px -2px rgb(0 0 0/20%);
}

.card_mantenimiento_header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-bottom: 1px solid #dcdcdc;
  background-color: #f2f2f2;

}

.card_mantenimiento_header span {
  font-size: 16px;
}

.card_mantenimiento_header p {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.card_mantenimiento_footer {
  background-color: #f2f2f2;
  padding: 10px 20px;
  display: flex;
  justify-content: end;
  border-top: 1px solid #dcdcdc;
}

.card_mantenimiento_footer button {
  border: none;
  background-color: #1890ff;
  color: #fff;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.card_mantenimiento_body {
  padding: 0px 20px 30px 20px;
}

.card_mantenimiento_body div {
  margin-bottom: 4px;
}

.info_mantenimieto {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.info_mantenimieto span {
  font-size: 20px;
  color: #999;
}


.select_month {
  width: 100%;
}

.ant-calendar-month-panel-table tbody tr:hover {
  background-color: transparent !important;
  color: #000 !important;
}

.bloquear_mantenimiento {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed !important;
  color: #999;
}

.center_item_mantenimiento {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list_servicio {
  width: 340px;
  white-space: pre-line;
  margin-bottom: 0px;
}

.list_servicio li {
  margin: 5px 0px;
}



.header_card_mantenimiento {
  border: 1px solid #dcdcdc;

}

.card_mantenimiento_titulo {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #dcdcdc;
  padding: 10px;
}

.preseleccionar_pieza {
  border: 1px solid #1890ff;
  box-shadow: 0 0 6px;
  animation: infinite resplandorPieza 2s;
}

@keyframes resplandorPieza {

  0%,
  100% {
    box-shadow: 0px 0px 4px #1890ff;
  }

  50% {
    box-shadow: 0px 0px 0px;
  }
}

.ContainerInitCatalogo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 121px);
}

.ContainerInitCatalogo div div {
  display: flex;
  justify-content: center;
}

.texto_bateria {
  margin-bottom: 40px;
}

.texto_bateria h1 {
  font-size: 45px;
  font-weight: bold;
  margin: 0px;
}

.table_modelos_vehiculos th {
  padding: 5px 12px !important;
}


.containerFabricantes {
  display: grid;
  padding-top: 20px;
  /*grid-template-columns: repeat(auto-fill, 110px);*/
  /*grid-template-columns: repeat(6, 110px);*/
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.containerFabricantes div {
  margin: 2px;
}

.lado_desactivo {
  height: 22px;
  cursor: pointer;
  opacity: 0.5;
  background-color: transparent;
}


.lado_activo {
  height: 22px;
  cursor: pointer;
  opacity: none;
  background-color: #1790ff;
}




.tituloCatalogoNeumatico {
  margin-bottom: 5px;
}

.tituloCatalogoNeumatico>span {
  font-size: 18px;
  font-weight: 500;

}

.bodyCatalogoNeumatico {
  font-size: 12px;
  font-weight: 500;
}

.body_masiva {
  padding: 20px;
  color: #000;
  height: calc(100vh - 61px);
}

.containerMasiva {
  margin-bottom: 20px;
  height: calc(100% - 60px);
  overflow: auto;

}

.footerMasiva {
  display: flex;
  align-items: center;
  justify-content: space-between;
}




.pantallaBloqueo {
  opacity: 1;
  cursor: default !important;
  pointer-events: fill;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, .8);
  transition: opacity .3s;
  height: 100%;
}


.log_masiva {
  border: 1px solid #646464;
  border-radius: 3px;
  padding: 10px;
  background: rgb(249, 201, 201);
  height: 400px;
  overflow: auto;
}

.container_form_lubricante {
  color: #000;
}

.container_form_lubricante span {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.container_form_separator {
  padding: 5px 0px;
}


.body_lubricantes {
  display: grid;
  grid-auto-flow: dense;
  /*grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));*/
  grid-template-columns: 400px auto;
  padding-top: 10px;


}


.menu_lubricantes {
  height: calc(100vh - 140px);
  overflow: auto;
  padding: 0px 10px;


}

.content_lubricantes {
  height: calc(100vh - 140px);
  overflow: auto;
  overflow-x: hidden;
  margin-right: 10px;
}

.clear_lubricante {
  border: none;
  color: #000;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  height: 100%;
  background-color: #dcdcdc;
  padding: 0px 10px;
}

.clear_lubricante span {
  font-size: 20px;
}


.container_cesta_sertec {
  margin: 0px 20px 0px 20px;
  border: 1px solid #dcdcdc;
  padding-left: 10px;
  padding-right: 10px;
  height: 480px;

}

.searchMasinfo div {
  background-color: #fff;
}

.searchMasinfo div button {
  border: none;
}

.searchMasinfo div input {
  width: 380px;
}

.search_btn_buscador {
  background-color: #fff;
  color: rgb(102, 102, 102);
}

.search_separdador {
  width: 2px;
  margin: 5px 0px;
  background-color: #ccc !important;
}


.container_info_cesta {
  display: flex;
  gap: 10px;
  align-items: center;
}

.titulo_registro_info {
  font-weight: 500;
  font-size: 14px;
  color: #323639;
}

.descripcion_registro_info {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}


.container_registro_info_cesta {
  display: flex;
  gap: 5px;
  align-items: center;
}

.subrayar_texto {
  text-decoration: line-through red;
}

.btn_oferta_color {
  color: #fff !important;
  background-color: rgb(252, 52, 52);
}

.btn_oferta button {
  height: 40px;
  width: 150px;
  border: none;
  margin-bottom: 5px;
  color: #fff;
  background-color: rgb(252, 52, 52);
  border-radius: 3px;
  /*
  border-top-left-radius: 10px;
  border-bottom-left-radius: 20px;


    border-right: 50px solid #000;
    
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;

  
*/

  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.titulo_adicional_oferta {
  padding: 20px 20px 10px 20px;
  border-bottom: 1px solid #dcdcdc;
  font-size: 16px;
  color: #1790ff;
}

.descripcion_adicional_oferta {
  padding: 10px 20px 0px 20px;
  font-size: 14px;
  color: #333;

  height: 80px;
  overflow: auto;
}

.container_precio_ref {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: end;
}

.icono_oferta {
  height: 15px;
  width: 15px;
  background: red;
  position: relative;
}

.icono_oferta::before,
.icono_oferta::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background: red;
}

.icono_oferta:before {
  transform: rotate(30deg);
}

.icono_oferta:after {
  transform: rotate(60deg);
}

.porcentaje_oferta {
  color: #fff;
  font-size: 10px;
  z-index: 100;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: -webkit-fill-available;
}


.searchVehiculo {
  width: -webkit-fill-available;
  margin: 20px 0px;
  border: 1px solid #dcdcdc;
}

.searchVehiculo div:first-child {
  flex: 1
}

.searchVehiculo div {
  background-color: #f2f2f2;
}

.searchVehiculo div button {
  border: none;
  background-color: #f2f2f2;
}

.searchVehiculo div input {
  width: 100%;
}

.container_grid_coche {
  height: calc(100vh - 220px);
  overflow: auto;
}


.container_list_marca_familia {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 5px;
  padding-right: 5px;


}

.item_rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--destacado);
  padding: 2px 0px;

  /*border: 1px solid #dcdcdc;*/

  color: #000;
}


.item_rating:hover {
  background-color: #495057 !important;
  color: #fff;
}

.container_grid_detalle_coche {
  height: calc(100vh - 290px);
  overflow: auto;
}

.disable_button {
  pointer-events: none;
  background-color: #cccc !important;
  opacity: 0.4 !important;
  cursor: not-allowed !important;
  border: 2px outset ButtonFace !important;

}

.container_texto_albaran {
  height: calc(100vh - 220px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container_doc_mantenimientos button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 10px;
  border: 0;
  background-color: #1890ff;
  color: #fff;
  font-size: 15px;
  cursor: pointer !important;
  border-radius: 3px;
}

.container_doc_mantenimientos button span {
  font-size: 20px;
}

.container_ficha_taller {
  height: calc(100vh - 61px);
  padding: 20px;
}

.containerTablaClientes {
  height: calc(100vh - 213px);
  overflow: auto;
}

.center_msg_clientes {
  height: calc(100vh - 213px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerHeaderClientes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.itemHeaderClientes {
  display: flex;

  gap: 10px;
}

.itemHeaderClientes button {
  color: #1890ff;
  border: 0;
  padding: 7px 10px;
  background-color: transparent;
  border: 3px;

  display: flex;
  align-items: center;
  gap: 5px;
}

.itemHeaderClientes button:hover {
  background-color: #cccc;
}


.searchCliente {
  width: 400px;
  margin: 0px;
  border: 1px solid #dcdcdc;
}

.searchCliente div:first-child {
  flex: 1
}

.searchCliente div {
  background-color: #f2f2f2;
}

.searchCliente div button {
  border: none;
  background-color: #f2f2f2;
}

.searchCliente div input {
  width: 100%;
}


.dropdownDoc {
  position: relative;
  display: inline-block;
}

.dropdownDoc-content {
  display: none;
  position: absolute;
  background-color: #dcdcdc;
  min-width: 200px;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
  padding: .5rem;
  z-index: 100;
  border-radius: 3px;
  /*border: 1px solid rgba(0,0,0,.15);*/

}

.dropdownDoc-content p:last-child {
  margin-bottom: 0px;
}

.dropdownDoc-content p {
  padding: 8px;
  margin-bottom: 5px;
}

.dropdownDoc-content p:hover {
  background-color: #c9c9c9;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.1s linear;
  cursor: pointer !important;
}

.dropdownDoc-content p a {
  all: unset;
}

.dropdownDoc:hover .dropdownDoc-content {
  display: block;
}

.centerDocVisor {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 61px);
}

.centerDocVisor img {
  width: 60%;
}

.img_cesta_articulo{
  height: 100px;
}

.altura_iframe_lateral{
  height: calc(100vh - 60px) !important;
  width: 100wv;
}