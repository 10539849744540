.btn_container{
    border: none;
    display: flex;
    align-items: center;
    border-radius: 3px;
    font-size: 20px !important;
    color: #1890ff;
    cursor: pointer;
    gap: 5px;
    padding: 10px 15px;

    

}

.btn_container span{
    font-size: 20px;
}